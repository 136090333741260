import React from "react";
import Error from "../error/error";
import state, { StateParameter } from "../../utils/state";
import { Settle } from "settle_planner";
import { backendAPI } from "settle_planner/dist/esm/App";
import base64_utf8 from "../../utils/base64";
import Login from "../login/login";

interface IProps {}

const Planner: React.FC<IProps> = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const paremeterState = queryParameters.get("s");
  const userState = new state();

  if (!paremeterState) {
    return <Error errorCode={405} />;
  }

  const stateUuid = userState.getParameter(StateParameter.customerUuid);
  const authInfoResponseString = decodeURIComponent(paremeterState);
  const data = JSON.parse(new base64_utf8().decode(authInfoResponseString));
  const parameterUuid = data[StateParameter.customerUuid];

  if (stateUuid !== parameterUuid && stateUuid !== "") {
    localStorage.clear();
    return <Login />;
  }
  
  const isSaved = userState.save(paremeterState);
  if (!isSaved) {
    return <Error errorCode={406} />;
  }

  const customerId = userState.getParameter(StateParameter.customerPublicId);

  let backendApi = backendAPI.live;

  if (process.env.REACT_APP_BACKEND_URL) {
    if (process.env.REACT_APP_BACKEND_URL?.indexOf("stage") > -1) {
      backendApi = backendAPI.stage;
    }
    if (process.env.REACT_APP_BACKEND_URL?.indexOf("localhost") > -1) {
      backendApi = backendAPI.localhost;
    }
  }

  console.log("customerId:", customerId);
  return <Settle userId={customerId} onConcentApproved={() => {}} backendApi={backendApi} />;
};

export default Planner;
