import "./error.css";

interface IProps {
  errorCode: number;
}

const Error: React.FC<IProps> = (props) => {
  console.log("Error code:", props.errorCode);
  return (
    <>
      <div className="error">
        <div className="logo">Settle</div>
        <div className="background"></div>
        <div className="centerVertical errorContainer">
          <div className="errorMessage">
            {/* 404 - Page not found */}
            {props.errorCode === 404 && <div>Siden kunne ikke findes</div>}
            {/* 405 - State missing in url */}
            {props.errorCode === 405 && <div>Der er opstået en fejl</div>}
            {/* 406 - Error decoding State */}
            {props.errorCode === 406 && <div>Der er opstået en fejl</div>}

            {/* 500 - Error connecting to server */}
            {props.errorCode === 500 && <div>Der er opstået en fejl, prøv igen om lidt.</div>}
            {/* 501 - Error getting data from server */}
            {props.errorCode === 501 && <div>Der er opstået en fejl, prøv igen om lidt.</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
