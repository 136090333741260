import React from "react";
import "./login.css";
import { useQuery, gql } from "@apollo/client";
import state, { StateParameter } from "../../utils/state";
import { useNavigate } from "react-router-dom";
import Error from "../error/error";

interface IProps { }

const GET_LOGIN_URL = gql`
  query getLoginUrl($input: getLoginUrlInput!) {
    getLoginUrl(input: $input)
  }
`;

const Login: React.FC<IProps> = (props) => {
  const navigate = useNavigate();


  const queryParameters = new URLSearchParams(window.location.search);
  const realEstateOfficePublicId_QP = queryParameters.get("broker");



  const userState = new state();
  const customerUuid = userState.getParameter(StateParameter.customerUuid);
  const customerExperireDate = userState.getParameter(StateParameter.ExperireDate);

  let realEstateOfficePublicId = process.env.REACT_APP_FALLBACK_BROKER;
  if (realEstateOfficePublicId_QP) {
    realEstateOfficePublicId = realEstateOfficePublicId_QP;
  }

  let variableData = {
    uuid: customerUuid,
    realEstateOfficePublicId: realEstateOfficePublicId
  } as any

  //C&B integration
  if (queryParameters.get("CaseNo")) {
    variableData["caseNo"] = queryParameters.get("CaseNo") + '';
  }
  if (queryParameters.get("newAddress")) {
    variableData["newAddress"] = decodeURIComponent(queryParameters.get("newAddress") + '').replaceAll("+", " ");
  }
  if (queryParameters.get("type")) {
    variableData["type"] = queryParameters.get("type") + '';
  }
  if (queryParameters.get("email")) {
    variableData["email"] = queryParameters.get("email") + '';
  }
  if (queryParameters.get("phone")) {
    variableData["phone"] = queryParameters.get("phone") + '';
  }
  if (queryParameters.get("movingDate")) {
    variableData["movingDate"] = queryParameters.get("movingDate") + '';
  }

  const { loading, error, data } = useQuery(GET_LOGIN_URL, {
    variables: {
      input: variableData
    }
  });

  if (loading) return <p></p>; //loading
  if (error) {
    return <Error errorCode={500} />;
  }
  if (!data) {
    return <Error errorCode={501} />;
  }

  if (new Date(customerExperireDate) > new Date()) {
    navigate("/planner?s=" + userState.getEncodedState());
  } else {
    localStorage.clear();
  }

  const handleLoginClicked = () => {
    window.location = data.getLoginUrl;
  };



  return (
    <div className="login">
      {/*}
      <div className="loginLogo">Settle</div>
      {*/}
      <div className="loginBackground"></div>
      <div className="centerVertical loginContainer">
        <div>
          <div className="HeadingContainer">
            <h1 className="screen">Settle</h1>
            <h2 className="screen">- din personlige flytteguide</h2>
            <h2 className="mobile">Din personlige<br/>flytteguide</h2>
          </div>
          <div className="SubHeadingContainer">
            <h3>
              Den gennemsnitlige dansker flytter godt 6 gange i sit liv - vi flytter mere end 600 gange om måneden.<br />
              Med andre ord, så har vi allerede helt styr på din to-do, så du hurtigt og nemt kommer rigtig godt fra start og får det fulde overblik - både før, under og efter flytningen.
              <br />
              <br />
              Kort fortalt - vi har helt styr på din flytning.
            </h3>
          </div>

          {/*
          <div className="MobileContainerMobile mobile">
            <img src="./mobile.svg" className="MobileImage" alt="Settle Mobile" />

          </div>
          */}
          <div className="loginBoxContainer">
            <div className="textContainer">
              <p>
                <b className="textContainerHeading screen">Log ind i din flytteguide nu</b>
                <b className="textContainerHeading mobile">Log ind i din flytteguide nu</b>
              </p>
              <p className="textContainerP">For din sikkerhed bruger vi MitID til login - og bare rolig, dine data er i gode hænder.</p>
              <br />
              <button
                className="loginButton"
                onClick={() => {
                  handleLoginClicked();
                }}
              >
                <img src="./MitID_logo.svg" className="MitID_ButtonLogo" alt="MitID logo" />
                <div className="MitID_ButtonText">Log ind med MitID</div>
              </button>
            </div>
          </div>
        </div>
        <div className="MobileContainer">
          <img src="./mobile.png" className="MobileImage" alt="Settle Mobile" />
        </div>




      </div>
    </div>
  );
};
export default Login;
