import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import './index.css';
import Login from "./pages/login/login";
import Error from "./pages/error/error";
import Planner from "./pages/planner/planner"

const client = new ApolloClient({
  uri:  process.env.REACT_APP_BACKEND_URL,
  cache: new InMemoryCache(),
  headers: {
    authorization:
      'Bearer ' + process.env.REACT_APP_BEARER_SECRET,
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const loginRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/planner",
    element: <Planner />
  },
  {
    path: "*",
    element: <Error errorCode={404} />
  }
]);

root.render(
  <ApolloProvider client={client}>
    <RouterProvider router={loginRouter} />
  </ApolloProvider>
);

