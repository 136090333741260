import base64_utf8 from "./base64";

export enum StateParameter {
  customerName = "customerName",
  customerUuid = "customerUuid",
  customerPublicId = "customerPublicId",
  realEstateOfficePublicId = "realEstateOfficePublicId",
  loginType = "loginType",
  ExperireDate = "ExperireDate",
}

export default class state {
  save = (encodedState: string) => {
    //insure that it can be decoded
    try {
      const authInfoResponseString = decodeURIComponent(encodedState);
      JSON.parse(new base64_utf8().decode(authInfoResponseString));

      localStorage.setItem("state", encodedState);

      return true;
    } catch {
      return false;
    }
  };

  getEncodedState = () => {
    return localStorage.getItem("state");
  }

  getParameter = (parameter: StateParameter) => {
    try {
      const encodedState = localStorage.getItem("state");
      if (!encodedState) {
        return "";
      }

      const authInfoResponseString = decodeURIComponent(encodedState);
      const data = JSON.parse(new base64_utf8().decode(authInfoResponseString));

      if (!data[parameter]) {
        return "";
      }
      return data[parameter];
    } catch {
      return "";
    }
  };
}
